import React from "react";
import { graphql } from "gatsby";
import { BLOCKS, INLINES } from "@contentful/rich-text-types";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"; // to read rich-text json into react components

import * as styles from "./FoodMenuTemplate.module.scss";
import Layout from "../components/layout/layout";

// just for template files we don't import {useStaticQuery}
// 1. graphql will run the query
// 2. $slug will come from the context we setup when created the page
// 3. it will export as (props.data) in the page
export const query = graphql`
    query($slug: String!) {
        contentfulFoodMenuItem(slug: { eq: $slug }) {
            title
            publishedDate(formatString: "D MMM, YYYY")
            image {
                file {
                    url
                }
            }
        }
    }
`;

// old query with body
// export const query = graphql`
//     query($slug: String!) {
//         contentfulFoodMenuItem(slug: { eq: $slug }) {
//             title
//             publishedDate(formatString: "D MMM, YYYY")
//             image {
//                 file {
//                     url
//                 }
//             }
//             body {
//                 raw
//                 references {
//                     ... on ContentfulAsset {
//                         __typename
//                         contentful_id
//                         file {
//                             contentType
//                             details {
//                                 image {
//                                     height
//                                     width
//                                 }
//                                 size
//                             }
//                             fileName
//                             url
//                         }
//                         title
//                         description
//                     }
//                 }
//             }
//             body {
//                 raw
//                 references {
//                     ... on ContentfulAsset {
//                         __typename
//                         contentful_id
//                         file {
//                             contentType
//                             details {
//                                 image {
//                                     height
//                                     width
//                                 }
//                                 size
//                             }
//                             fileName
//                             url
//                         }
//                     }
//                 }
//             } body {
//                 raw
//                 references {
//                     ... on ContentfulAsset {
//                         __typename
//                         contentful_id
//                         file {
//                             contentType
//                             details {
//                                 image {
//                                     height
//                                     width
//                                 }
//                                 size
//                             }
//                             fileName
//                             url
//                         }
//                     }
//                 }
//             }
//         }
//     }
// `;

const FoodMenuPost = props => {
    return (
        <Layout title={props.data.contentfulFoodMenuItem.title}>
            <div className={styles.page}>
                <div className={styles.container}>
                    <div className={styles.imageContainer}>
                        <img
                            className={styles.menuImage}
                            src={props.data.contentfulFoodMenuItem.image.file.url}
                            width="580"
                            height="400"
                            alt="Ultimate Aqua"
                    />
                    </div>
                    <p>Invented on {props.data.contentfulFoodMenuItem.publishedDate} © DL. All Rights Reserved.</p>
                    <div className={styles.body}> 
                        {/* {RichTextResponse(props.data.contentfulFoodMenuItem.body)} */}
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default FoodMenuPost;

// Create a bespoke renderOptions object to target BLOCKS.EMBEDDED_ENTRY (linked entries e.g. videoEmbed)
// and BLOCKS.EMBEDDED_ASSET (linked assets e.g. images)
function renderOptions(links) {
    console.log(links);
    // create an asset block map
    const assetBlockMap = new Map();
    // loop through the assets and add them to the map
    for (const link of links) {
        assetBlockMap.set(link.contentful_id, {
            file: link.file,
            title: link.title,
        });
    }
    console.log(assetBlockMap);

    return {
        renderNode: {
            [BLOCKS.EMBEDDED_ASSET]: (node, next) => {
                // find the asset in the assetBlockMap by ID
                const asset = assetBlockMap.get(node.data.target.sys.id);

                console.log(node);
                console.log(asset);

                return <img src={asset.file.url} alt={asset.title} />;
            },
            [INLINES.HYPERLINK]: node => {
                console.log(node);
                if (node.data.uri.indexOf("youtube.com") > 0) {
                    return (
                        <iframe
                            width="560"
                            height="315"
                            src={node.data.uri}
                            frameBorder="0"
                            title={node.data.uri}
                            allow="accelerometer; autoplay; engrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                        ></iframe>
                    );
                }
            },
        },
    };
}

// Render richTextResponse.json to the DOM using
// documentToReactComponents from "@contentful/rich-text-react-renderer"
// function RichTextResponse(richTextResponse) {
//     console.log(richTextResponse);
//     return (
//         <>
//             {documentToReactComponents(
//                 JSON.parse(richTextResponse.raw),
//                 renderOptions(richTextResponse.references)
//             )}
//         </>
//     );
//}
